import React from 'react';

const InSlider = () => {
    return (
    <div id="inSlider" className="carousel slide" data-ride="carousel">
        <ol className="carousel-indicators">
            <li data-target="#inSlider" data-slide-to={0} className="active" />
            <li data-target="#inSlider" data-slide-to={1} />
        </ol>
        <div className="carousel-inner" role="listbox">
            <div className="carousel-item active">
                <div className="container">
                    <div className="carousel-caption">
                        <h1>PLANETE / RESEAUX.
                            </h1>
                        <p> Faites-nous confiance <br />
                            dans l'odit de reseaux, <br/> 
                            la maintenance et securité reseau</p>
                        <p>
                        <p>
                        Protégez votre entreprise et sécuriser vos données
                        </p>
                            <a className="btn btn-lg btn-primary" href="#" role="button">READ MORE</a>
                            {/* <a className="caption-link" href="#" role="button">Inspinia Theme</a> */}
                        </p>
                    </div>
                    <div className="carousel-image wow zoomIn">
                        <img src="assets/img/landing/laptop.png" alt="laptop" />
                    </div>
                </div>
                {/* Set background for slide in css */}
                <div className="header-back one" />
            </div>
            <div className="carousel-item">
                <div className="container">
                    <div className="carousel-caption blank">
                        <h1>PLANETE / INTERNET</h1>
                        <p>Améliorez la productivité de <br/>
                        vos collaborateurs en tirant pleinement parti <br/>
                            des avantages du numérique</p>
                        <p><a className="btn btn-lg btn-primary" href="#features" role="button">Lire plus</a></p>
                    </div>
                </div>
                {/* Set background for slide in css */}
                <div className="header-back two" />
            
            </div>
        </div>
        <a className="carousel-control-prev" href="#inSlider" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href="#inSlider" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="sr-only">Next</span>
        </a>
    </div>

    );
}

export default InSlider;
