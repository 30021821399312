import React from 'react';
import Services from './Services';
import Team from './Team';

const Contents = () => {
    return (
        <div>
        <Services/>
        
        <Team/>
        <section className="features">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                    <div className="navy-line" />
                    <h1>Even more great feautres</h1>
                    <p>Donec sed odio dui. Etiam porta sem malesuada magna mollis euismod. </p>
                    </div>
                </div>
                <div className="row features-block">
                    <div className="col-lg-3 features-text wow fadeInLeft">
                    <small>INSPINIA</small>
                    <h2>Perfectly designed </h2>
                    <p>INSPINIA Admin Theme is a premium admin dashboard template with flat design concept. It is fully responsive admin dashboard template built with Bootstrap 3+ Framework, HTML5 and CSS3, Media query. It has a huge collection of reusable UI components and integrated with latest jQuery plugins.</p>
                    <a href className="btn btn-primary">Learn more</a>
                    </div>
                    <div className="col-lg-6 text-right m-t-n-lg wow zoomIn">
                    <img src="img/landing/iphone.jpg" className="img-fluid" alt="dashboard" />
                    </div>
                    <div className="col-lg-3 features-text text-right wow fadeInRight">
                    <small>INSPINIA</small>
                    <h2>Perfectly designed </h2>
                    <p>INSPINIA Admin Theme is a premium admin dashboard template with flat design concept. It is fully responsive admin dashboard template built with Bootstrap 3+ Framework, HTML5 and CSS3, Media query. It has a huge collection of reusable UI components and integrated with latest jQuery plugins.</p>
                    <a href className="btn btn-primary">Learn more</a>
                    </div>
                </div>
            </div>
        </section>
        <section className="timeline gray-section">
            <div className="container">
            <div className="row">
                <div className="col-lg-12 text-center">
                <div className="navy-line" />
                <h1>Our workflow</h1>
                <p>Donec sed odio dui. Etiam porta sem malesuada magna mollis euismod. </p>
                </div>
            </div>
            <div className="row features-block">
                <div className="col-lg-12">
                <div id="vertical-timeline" className="vertical-container light-timeline center-orientation">
                    <div className="vertical-timeline-block">
                    <div className="vertical-timeline-icon navy-bg">
                        <i className="fa fa-briefcase" />
                    </div>
                    <div className="vertical-timeline-content">
                        <h2>Meeting</h2>
                        <p>Conference on the sales results for the previous year. Monica please examine sales trends in marketing and products. Below please find the current status of the sale.
                        </p>
                        <a href="#" className="btn btn-xs btn-primary"> More info</a>
                        <span className="vertical-date"> Today <br /> <small>Dec 24</small> </span>
                    </div>
                    </div>
                    <div className="vertical-timeline-block">
                    <div className="vertical-timeline-icon navy-bg">
                        <i className="fa fa-file-text" />
                    </div>
                    <div className="vertical-timeline-content">
                        <h2>Decision</h2>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.</p>
                        <a href="#" className="btn btn-xs btn-primary"> More info</a>
                        <span className="vertical-date"> Tomorrow <br /> <small>Dec 26</small> </span>
                    </div>
                    </div>
                    <div className="vertical-timeline-block">
                    <div className="vertical-timeline-icon navy-bg">
                        <i className="fa fa-cogs" />
                    </div>
                    <div className="vertical-timeline-content">
                        <h2>Implementation</h2>
                        <p>Go to shop and find some products. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's. </p>
                        <a href="#" className="btn btn-xs btn-primary"> More info</a>
                        <span className="vertical-date"> Monday <br /> <small>Jan 02</small> </span>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>
        <section id="testimonials" className="navy-section testimonials" style={{marginTop: 0}}>
            <div className="container">
            <div className="row">
                <div className="col-lg-12 text-center wow zoomIn">
                <i className="fa fa-comment big-icon" />
                <h1>
                    What our users say
                </h1>
                <div className="testimonials-text">
                    <i>"Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."</i>
                </div>
                <small>
                    <strong>12.02.2014 - Andy Smith</strong>
                </small>
                </div>
            </div>
            </div>
        </section>
        <section className="comments gray-section" style={{marginTop: 0}}>
            <div className="container">
            <div className="row">
                <div className="col-lg-12 text-center">
                <div className="navy-line" />
                <h1>What our partners say</h1>
                <p>Donec sed odio dui. Etiam porta sem malesuada. </p>
                </div>
            </div>
            <div className="row features-block">
                <div className="col-lg-4">
                <div className="bubble">
                    "Uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."
                </div>
                <div className="comments-avatar">
                    <a href className="float-left">
                    <img alt="image" src="img/landing/avatar3.jpg" />
                    </a>
                    <div className="media-body">
                    <div className="commens-name">
                        Andrew Williams
                    </div>
                    <small className="text-muted">Company X from California</small>
                    </div>
                </div>
                </div>
                <div className="col-lg-4">
                <div className="bubble">
                    "Uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."
                </div>
                <div className="comments-avatar">
                    <a href className="float-left">
                    <img alt="image" src="img/landing/avatar1.jpg" />
                    </a>
                    <div className="media-body">
                    <div className="commens-name">
                        Andrew Williams
                    </div>
                    <small className="text-muted">Company X from California</small>
                    </div>
                </div>
                </div>
                <div className="col-lg-4">
                <div className="bubble">
                    "Uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."
                </div>
                <div className="comments-avatar">
                    <a href className="float-left">
                    <img alt="image" src="img/landing/avatar2.jpg" />
                    </a>
                    <div className="media-body">
                    <div className="commens-name">
                        Andrew Williams
                    </div>
                    <small className="text-muted">Company X from California</small>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>
        <section className="features">
            <div className="container">
            <div className="row">
                <div className="col-lg-12 text-center">
                <div className="navy-line" />
                <h1>More and more extra great feautres</h1>
                <p>Donec sed odio dui. Etiam porta sem malesuada magna mollis euismod. </p>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-5 col-lg-offset-1 features-text">
                <small>INSPINIA</small>
                <h2>Perfectly designed </h2>
                <i className="fa fa-bar-chart big-icon float-right" />
                <p>INSPINIA Admin Theme is a premium admin dashboard template with flat design concept. It is fully responsive admin dashboard template built with Bootstrap 3+ Framework, HTML5 and CSS3, Media query. It has a huge collection of reusable UI components and integrated with.</p>
                </div>
                <div className="col-lg-5 features-text">
                <small>INSPINIA</small>
                <h2>Perfectly designed </h2>
                <i className="fa fa-bolt big-icon float-right" />
                <p>INSPINIA Admin Theme is a premium admin dashboard template with flat design concept. It is fully responsive admin dashboard template built with Bootstrap 3+ Framework, HTML5 and CSS3, Media query. It has a huge collection of reusable UI components and integrated with.</p>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-5 col-lg-offset-1 features-text">
                <small>INSPINIA</small>
                <h2>Perfectly designed </h2>
                <i className="fa fa-clock-o big-icon float-right" />
                <p>INSPINIA Admin Theme is a premium admin dashboard template with flat design concept. It is fully responsive admin dashboard template built with Bootstrap 3+ Framework, HTML5 and CSS3, Media query. It has a huge collection of reusable UI components and integrated with.</p>
                </div>
                <div className="col-lg-5 features-text">
                <small>INSPINIA</small>
                <h2>Perfectly designed </h2>
                <i className="fa fa-users big-icon float-right" />
                <p>INSPINIA Admin Theme is a premium admin dashboard template with flat design concept. It is fully responsive admin dashboard template built with Bootstrap 3+ Framework, HTML5 and CSS3, Media query. It has a huge collection of reusable UI components and integrated with.</p>
                </div>
            </div>
            </div>
        </section>
        <section id="pricing" className="pricing">
            <div className="container">
            <div className="row m-b-lg">
                <div className="col-lg-12 text-center">
                <div className="navy-line" />
                <h1>App Pricing</h1>
                <p>Donec sed odio dui. Etiam porta sem malesuada magna mollis euismod.</p>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4 wow zoomIn">
                <ul className="pricing-plan list-unstyled">
                    <li className="pricing-title">
                    Basic
                    </li>
                    <li className="pricing-desc">
                    Lorem ipsum dolor sit amet, illum fastidii dissentias quo ne. Sea ne sint animal iisque, nam an soluta sensibus.
                    </li>
                    <li className="pricing-price">
                    <span>$16</span> / month
                    </li>
                    <li>
                    Dashboards
                    </li>
                    <li>
                    Projects view
                    </li>
                    <li>
                    Contacts
                    </li>
                    <li>
                    Calendar
                    </li>
                    <li>
                    AngularJs
                    </li>
                    <li>
                    <a className="btn btn-primary btn-xs" href="#">Signup</a>
                    </li>
                </ul>
                </div>
                <div className="col-lg-4 wow zoomIn">
                <ul className="pricing-plan list-unstyled selected">
                    <li className="pricing-title">
                    Standard
                    </li>
                    <li className="pricing-desc">
                    Lorem ipsum dolor sit amet, illum fastidii dissentias quo ne. Sea ne sint animal iisque, nam an soluta sensibus.
                    </li>
                    <li className="pricing-price">
                    <span>$22</span> / month
                    </li>
                    <li>
                    Dashboards
                    </li>
                    <li>
                    Projects view
                    </li>
                    <li>
                    Contacts
                    </li>
                    <li>
                    Calendar
                    </li>
                    <li>
                    AngularJs
                    </li>
                    <li>
                    <strong>Support platform</strong>
                    </li>
                    <li className="plan-action">
                    <a className="btn btn-primary btn-xs" href="#">Signup</a>
                    </li>
                </ul>
                </div>
                <div className="col-lg-4 wow zoomIn">
                <ul className="pricing-plan list-unstyled">
                    <li className="pricing-title">
                    Premium
                    </li>
                    <li className="pricing-desc">
                    Lorem ipsum dolor sit amet, illum fastidii dissentias quo ne. Sea ne sint animal iisque, nam an soluta sensibus.
                    </li>
                    <li className="pricing-price">
                    <span>$160</span> / month
                    </li>
                    <li>
                    Dashboards
                    </li>
                    <li>
                    Projects view
                    </li>
                    <li>
                    Contacts
                    </li>
                    <li>
                    Calendar
                    </li>
                    <li>
                    AngularJs
                    </li>
                    <li>
                    <a className="btn btn-primary btn-xs" href="#">Signup</a>
                    </li>
                </ul>
                </div>
            </div>
            <div className="row m-t-lg">
                <div className="col-lg-12 text-center m-t-lg">
                <p>*Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. <span className="navy">Various versions</span>  have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
                </div>
            </div>
            </div>
        </section>
        <section id="contact" className="gray-section contact">
            <div className="container">
            <div className="row m-b-lg">
                <div className="col-lg-12 text-center">
                <div className="navy-line" />
                <h1>Contact Us</h1>
                <p>Donec sed odio dui. Etiam porta sem malesuada magna mollis euismod.</p>
                </div>
            </div>
            <div className="row m-b-lg justify-content-center">
                <div className="col-lg-3 ">
                <address>
                    <strong><span className="navy">Company name, Inc.</span></strong><br />
                    795 Folsom Ave, Suite 600<br />
                    San Francisco, CA 94107<br />
                    <abbr title="Phone">P:</abbr> (123) 456-7890
                </address>
                </div>
                <div className="col-lg-4">
                <p className="text-color">
                    Consectetur adipisicing elit. Aut eaque, totam corporis laboriosam veritatis quis ad perspiciatis, totam corporis laboriosam veritatis, consectetur adipisicing elit quos non quis ad perspiciatis, totam corporis ea,
                </p>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 text-center">
                <a href="mailto:test@email.com" className="btn btn-primary">Send us mail</a>
                <p className="m-t-sm">
                    Or follow us on social platform
                </p>
                <ul className="list-inline social-icon">
                    <li className="list-inline-item"><a href="#"><i className="fa fa-twitter" /></a>
                    </li>
                    <li className="list-inline-item"><a href="#"><i className="fa fa-facebook" /></a>
                    </li>
                    <li className="list-inline-item"><a href="#"><i className="fa fa-linkedin" /></a>
                    </li>
                </ul>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 text-center m-t-lg m-b-lg">
                <p><strong>© 2015 Company Name</strong><br /> consectetur adipisicing elit. Aut eaque, laboriosam veritatis, quos non quis ad perspiciatis, totam corporis ea, alias ut unde.</p>
                </div>
            </div>
            </div>
        </section>
        </div>
    );
}

export default Contents;
