import React from 'react';

const Navbar = () => {
    return (
        <div className="navbar-wrapper">
            <nav className="navbar navbar-default navbar-fixed-top navbar-expand-md navbar-scroll" role="navigation">
                <div className="container">
                <a className="navbar-brand" href="index.html">PLANETE SERVICE</a>
                <div className="navbar-header page-scroll">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar">
                    <i className="fa fa-bars" />
                    </button>
                </div>
                <div className="collapse navbar-collapse justify-content-end" id="navbar">
                    <ul className="nav navbar-nav navbar-right">
                    <li><a className="nav-link page-scroll" href={"#page-top"}>Accueil</a></li>
                    <li><a className="nav-link page-scroll" href={"#features"}>Services</a></li>
                    <li><a className="nav-link page-scroll" href={"#team"}>Equipe</a></li>
                    <li><a className="nav-link page-scroll" href={"#testimonials"}>Nos produits</a></li>
                    <li><a className="nav-link page-scroll" href={"#pricing"}>Nos realisations</a></li>
                    <li><a className="nav-link page-scroll" href={"#contact"}>Contact</a></li>
                    </ul>
                </div>
                </div>
            </nav>
        </div>

    );
}

export default Navbar;
