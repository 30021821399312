import React from 'react';

const Services = () => {
    return (
        <>
            
            <section id="features" className="container services">
                <div className="row">
                <div className="col-sm-3">
                    <h2>Full responsive</h2>
                    <p>Donec sed odio dui. Etiam porta sem malesuada magna mollis euismod. Nullam id dolor id nibh ultricies vehicula ut id elit. Morbi leo risus.</p>
                    <p><a className="navy-link" href="#" role="button">Details »</a></p>
                </div>
                <div className="col-sm-3">
                    <h2>LESS/SASS Files</h2>
                    <p>Donec sed odio dui. Etiam porta sem malesuada magna mollis euismod. Nullam id dolor id nibh ultricies vehicula ut id elit. Morbi leo risus.</p>
                    <p><a className="navy-link" href="#" role="button">Details »</a></p>
                </div>
                <div className="col-sm-3">
                    <h2>6 Charts Library</h2>
                    <p>Donec sed odio dui. Etiam porta sem malesuada magna mollis euismod. Nullam id dolor id nibh ultricies vehicula ut id elit. Morbi leo risus.</p>
                    <p><a className="navy-link" href="#" role="button">Details »</a></p>
                </div>
                <div className="col-sm-3">
                    <h2>Advanced Forms</h2>
                    <p>Donec sed odio dui. Etiam porta sem malesuada magna mollis euismod. Nullam id dolor id nibh ultricies vehicula ut id elit. Morbi leo risus.</p>
                    <p><a className="navy-link" href="#" role="button">Details »</a></p>
                </div>
                </div>
            </section>
            <section className="container features">
                <div className="row">
                <div className="col-lg-12 text-center">
                    <div className="navy-line" />
                    <h1>Over 40+ unique view<br /> <span className="navy"> with many custom components</span> </h1>
                    <p>Donec sed odio dui. Etiam porta sem malesuada magna mollis euismod. </p>
                </div>
                </div>
                <div className="row">
                <div className="col-md-3 text-center wow fadeInLeft">
                    <div>
                    <i className="fa fa-mobile features-icon" />
                    <h2>Full responsive</h2>
                    <p>Donec sed odio dui. Etiam porta sem malesuada magna mollis euismod. Nullam id dolor id nibh ultricies vehicula ut id elit. Morbi leo risus.</p>
                    </div>
                    <div className="m-t-lg">
                    <i className="fa fa-bar-chart features-icon" />
                    <h2>6 Charts Library</h2>
                    <p>Donec sed odio dui. Etiam porta sem malesuada magna mollis euismod. Nullam id dolor id nibh ultricies vehicula ut id elit. Morbi leo risus.</p>
                    </div>
                </div>
                <div className="col-md-6 text-center  wow zoomIn">
                    <img src="assets/img/landing/perspective.png" alt="dashboard" className="img-fluid" />
                </div>
                <div className="col-md-3 text-center wow fadeInRight">
                    <div>
                    <i className="fa fa-envelope features-icon" />
                    <h2>Mail pages</h2>
                    <p>Donec sed odio dui. Etiam porta sem malesuada magna mollis euismod. Nullam id dolor id nibh ultricies vehicula ut id elit. Morbi leo risus.</p>
                    </div>
                    <div className="m-t-lg">
                    <i className="fa fa-google features-icon" />
                    <h2>AngularJS version</h2>
                    <p>Donec sed odio dui. Etiam porta sem malesuada magna mollis euismod. Nullam id dolor id nibh ultricies vehicula ut id elit. Morbi leo risus.</p>
                    </div>
                </div>
                </div>
                <div className="row">
                <div className="col-lg-12 text-center">
                    <div className="navy-line" />
                    <h1>Discover great feautres</h1>
                    <p>Donec sed odio dui. Etiam porta sem malesuada magna mollis euismod. </p>
                </div>
                </div>
                <div className="row features-block">
                <div className="col-lg-6 features-text wow fadeInLeft">
                    <small>INSPINIA</small>
                    <h2>Perfectly designed </h2>
                    <p>INSPINIA Admin Theme is a premium admin dashboard template with flat design concept. It is fully responsive admin dashboard template built with Bootstrap 3+ Framework, HTML5 and CSS3, Media query. It has a huge collection of reusable UI components and integrated with latest jQuery plugins.</p>
                    <a href className="btn btn-primary">Learn more</a>
                </div>
                <div className="col-lg-6 text-right wow fadeInRight">
                    <img src="assets/img/landing/dashboard.png" alt="dashboard" className="img-fluid float-right" />
                </div>
                </div>
            </section>
        </>
    );
}

export default Services;
