import React from 'react';

const Team = () => {
    return (
        <div>
            <section id="team" className="gray-section team">
                <div className="container">
                <div className="row m-b-lg">
                    <div className="col-lg-12 text-center">
                    <div className="navy-line" />
                    <h1>Our Team</h1>
                    <p>Donec sed odio dui. Etiam porta sem malesuada magna mollis euismod.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4 wow fadeInLeft">
                    <div className="team-member">
                        <img src="img/landing/avatar3.jpg" className="img-fluid rounded-circle img-small" alt />
                        <h4><span className="navy">Amelia</span> Smith</h4>
                        <p>Lorem ipsum dolor sit amet, illum fastidii dissentias quo ne. Sea ne sint animal iisque, nam an soluta sensibus. </p>
                        <ul className="list-inline social-icon">
                        <li className="list-inline-item"><a href="#"><i className="fa fa-twitter" /></a>
                        </li>
                        <li className="list-inline-item"><a href="#"><i className="fa fa-facebook" /></a>
                        </li>
                        <li className="list-inline-item"><a href="#"><i className="fa fa-linkedin" /></a>
                        </li>
                        </ul>
                    </div>
                    </div>
                    <div className="col-sm-4">
                    <div className="team-member wow zoomIn">
                        <img src="img/landing/avatar1.jpg" className="img-fluid rounded-circle" alt />
                        <h4><span className="navy">John</span> Novak</h4>
                        <p>Lorem ipsum dolor sit amet, illum fastidii dissentias quo ne. Sea ne sint animal iisque, nam an soluta sensibus.</p>
                        <ul className="list-inline social-icon">
                        <li className="list-inline-item"><a href="#"><i className="fa fa-twitter" /></a>
                        </li>
                        <li className="list-inline-item"><a href="#"><i className="fa fa-facebook" /></a>
                        </li>
                        <li className="list-inline-item"><a href="#"><i className="fa fa-linkedin" /></a>
                        </li>
                        </ul>
                    </div>
                    </div>
                    <div className="col-sm-4 wow fadeInRight">
                    <div className="team-member">
                        <img src="img/landing/avatar2.jpg" className="img-fluid rounded-circle img-small" alt />
                        <h4><span className="navy">Peter</span> Johnson</h4>
                        <p>Lorem ipsum dolor sit amet, illum fastidii dissentias quo ne. Sea ne sint animal iisque, nam an soluta sensibus.</p>
                        <ul className="list-inline social-icon">
                        <li className="list-inline-item"><a href="#"><i className="fa fa-twitter" /></a>
                        </li>
                        <li className="list-inline-item"><a href="#"><i className="fa fa-facebook" /></a>
                        </li>
                        <li className="list-inline-item"><a href="#"><i className="fa fa-linkedin" /></a>
                        </li>
                        </ul>
                    </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 text-center m-t-lg m-b-lg">
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut eaque, laboriosam veritatis, quos non quis ad perspiciatis, totam corporis ea, alias ut unde.</p>
                    </div>
                </div>
                </div>
            </section>
        </div>
    );
}

export default Team;
