// import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import InSlider from './components/InSlider';
import Contents from './components/Contents';

function App() {
  return (
    <div className="App">
      <Navbar/>
      <InSlider/>
      <Contents/>
    </div>
  );
}

export default App;
